import React, { Component } from "react";
import debounce from "lodash.debounce";
import "./footer.sass";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    window.addEventListener(
      "scroll",
      debounce(() => {
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight * 0.8
        ) {
          this.setState({
            items: this.state.items.concat(Array.from({ length: 20 })),
          });
        }
      }, 10)
    );
  }

  fetchMoreData = () => {};

  render() {
    const { config } = this.props;
    const rssUrl = config.siteRss;
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <b>contact:</b>
          <span className="icon is-medium">
            <i className="fa fa-envelope" />
          </span>
          <span>
            hi [at] trinityjchung.com
          </span>
          <p>
            site last updated: 2024/05/26
          </p>
          {/* <div style={{ display: "inline-block", width: "20px" }}>&nbsp;</div> */}
          {/* <b>connect:</b>
          <a href="https://twitter.com/milotrince" className="icon is-medium">
            <i className="fa fa-twitter"/>
          </a>
          <a href="https://www.linkedin.com/in/trinitychung/" className="icon is-medium">
            <i className="fa fa-linkedin"/>
          </a> */}
        </div>

        <div>
          {this.state.items.map((i, index) => (
            <span
              key={index}
              className="test"
              style={{ opacity: `${Math.min(index / 2000, 1)}` }}
            >
              the end is never&nbsp;
            </span>
          ))}
        </div>
      </footer>
    );
  }
}

export default Footer;
