import React, { Component } from "react"
import { graphql, Link } from "gatsby"
// import Search from '../../components/search/search'
import Layout from "../../layout"

class MusicPage extends Component {

  render() {
    const posts = this.props.data.allMarkdownRemark.nodes
    return (
      <Layout title='music'>
        <h1>music</h1>
        <h3>guitar chords & tabs</h3>
        <p>
        <img class="emote" src="/img/emotes/new.gif"/> check it out! <a href="https://setlist.trinityjchung.com/">setlist</a>
        </p>

        <h3>sheet music index</h3>
        <p>My old stash of transcribed/arranged sheet music for strings, piano, and guitar chords/tabs.</p>
        {/* <Search
          posts={posts}
          localSearch={this.props.data.localSearchMusic}
          placeholder='Search music posts...'
          prefix='music'
        /> */}
        {
          posts.map((p, i) => (
            <div key={i}>
              <a href={"/music/"+p.fields.slug}>{p.frontmatter.title}</a>
              {p.frontmatter.tags.map((t, j) => <span key={j}> ~ {t}</span>)}
            </div>
          ))
        }

        <h3>deprecated</h3>
        <p>
          <a href="https://musescore.com/groups/milotrince">MuseScore</a>
          &nbsp;~&nbsp;
          <a href="https://soundcloud.com/milotrince">SoundCloud</a>
        </p>
      </Layout>
    )
  }
}

export const query = graphql`
  query MusicPageQuery {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "music" }}}
    ) {
      nodes {
        fields {
          slug
          date
        }
        excerpt
        frontmatter {
          title
          tags
          cover
          date
        }
      }
    }
  }
`

export default MusicPage
